<template>
  <PositionLabel v-if="!small" :track="item" />
  <CoverImage v-if="!small" :track="item" />
  <div class="item_content" :class="{ small }">
    <h1 class="item_title">
      {{ item.title }}
    </h1>
    <p class="item_artist">
      {{ item.artist?.name }}
    </p>
  </div>
</template>

<script>
import PositionLabel from '../PositionLabel.vue'
import CoverImage from '../CoverImage.vue'

export default {
  name: 'CurrentTrack',
  components: { PositionLabel, CoverImage },
  props: {
    item: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixins.scss';

.item_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.item_title {
  @extend .title-text;
  margin: 0;
  color: rgb(var(--color-text-primary));
  font-size: 3rem;
  font-weight: 600;
  line-height: 1;
  text-overflow: ellipsis;
}

.item_artist {
  margin: 0;
  font-size: 2rem;
  font-family: var(--font-body);
  color: #374151;
  text-overflow: ellipsis;
}

.small {
  .item_title {
    font-size: 1rem;
  }

  .item_artist {
    font-size: 1.5rem;
  }
}
</style>
