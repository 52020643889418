<template>
  <div class="channel_list">
    <ChannelCard v-for="channel in filteredChannels" :key="channel.name" :channel="channel" />
  </div>
</template>

<script>
import { useChannelsStore } from '../stores/channels'
import { mapState } from 'pinia'
import ChannelCard from './ChannelCard.vue'

export default {
  name: 'ChannelList',
  components: {
    ChannelCard,
  },
  computed: {
    ...mapState(useChannelsStore, ['filteredChannels']),
  },
}
</script>

<style lang="scss" scoped>
.channel_list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
</style>
