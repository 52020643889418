<template>
  <div id="app" :class="theme">
    <div class="header">
      <CurrentProgram />
      <CurrentItem />
    </div>
    <div class="sidebar">
      <ChannelList />
      <Widgets />
    </div>
  </div>
</template>

<script>
import io from 'socket.io-client'
import { config } from '@dpgradio/creative'
import CurrentProgram from './components/CurrentProgram.vue'
import CurrentItem from './components/CurrentItem.vue'
import ChannelList from './components/ChannelList.vue'
import Widgets from './components/Widgets.vue'

export default {
  name: 'App',
  components: {
    CurrentProgram,
    CurrentItem,
    ChannelList,
    Widgets,
  },
  data() {
    return {
      socket: null,
    }
  },
  computed: {
    theme() {
      return config('app.theme')
    },
  },
  mounted() {
    this.listenToRefreshWebsocket()
  },
  beforeUnmount() {
    this.socket.close()
  },
  methods: {
    listenToRefreshWebsocket() {
      this.socket = io.connect(`https://wall.dario.fm/live-view-dashboards`)
      this.socket.on('reload', () => {
        location.reload()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#app {
  font-family: var(--font-primary);
  min-height: 100vh;
  height: 100vh;
  width: 100vw;
  min-width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    height: 170px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
  }

  .sidebar {
    height: calc(100vh - 170px);
    width: 270px;
    position: fixed;
    top: 170px;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .mychannels {
    position: absolute;
    bottom: 0;
    height: 100%;
    max-width: calc(100vw - 210px);
    max-height: calc(100vh - 170px);
    aspect-ratio: 16/9;
  }
}
</style>
