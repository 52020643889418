<template>
  <span v-if="position" class="position_label"> #{{ position }} </span>
</template>

<script>
export default {
  props: {
    track: {
      type: Object,
      default: null,
    },
  },
  computed: {
    position() {
      return this.track?.active_editions?.[0]?.position
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../style/mixins.scss';

.position_label {
  @extend .title-text;
  background-color: rgb(var(--color-primary));
  position: absolute;
  left: 30px;
  top: 2px;
  font-weight: bold;
  display: inline-block;
  z-index: 30;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  width: fit-content;
}
</style>
