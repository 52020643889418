<template>
  <div class="channel-card" :style="channelStyle">
    <div v-if="isMainChannel" class="channel-card__content main-channel-card">
      <p class="channel-card__title">actieve streams:</p>
      <div class="channel-card__listeners">{{ count }}</div>
    </div>
    <div v-else class="channel-card__content">
      <div class="channel-card__logo">
        <img :src="channel.data.logo.app_logo_white" alt="logo" />
      </div>
      <div class="channel-card__listeners">{{ count }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useChannelsStore } from '../stores/channels'

export default {
  name: 'ChannelCard',
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(useChannelsStore, ['listenersCountChannel', 'main']),
    isMainChannel() {
      return this.channel.station.id === this.main.station.id
    },
    count() {
      return this.listenersCountChannel(this.channel.station.id).toLocaleString('NL-be')
    },
    channelStyle() {
      return {
        'background-image': `url(${this.channel.data.logo.site_background})`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../style/mixins.scss';
.channel-card {
  height: fit-content;
  margin-bottom: -20px;
  padding: 5px 5px 20px;
  box-sizing: border-box;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  width: 100%;
  background-size: cover;
  border: 3px solid rgba(255, 255, 255);
  border-color: white;
  border-width: 3px 0 3px 3px;

  &:last-of-type {
    margin-bottom: 0;
    padding: 5px;
  }

  &__content {
    height: 100%;
    width: 100%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    gap: 0.2rem;
  }

  &__logo {
    height: 80px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  &__listeners {
    @extend .title-text;
    font-size: 4rem;
    font-weight: 600;
    text-align: center;
  }

  &__title {
    @extend .title-text;
    width: 100%;
    text-align: start;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
  }

  .main-channel-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
