<template>
  <div class="item_content">
    <h1 class="item_title">RECLAME</h1>
    <p class="item_brand">
      {{ item.creator }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixins.scss';
.item_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.item_title {
  @extend .title-text;
  margin: 0;
  color: rgb(var(--color-text-primary));
  font-size: 3rem;
  font-weight: 600;
  line-height: 1;
  text-overflow: ellipsis;
}

.item_brand {
  margin: 0;
  font-size: 2rem;
  font-family: var(--font-body);
  color: #374151;
  text-overflow: ellipsis;
}
</style>
