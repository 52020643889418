<template>
  <section class="program_container">
    <img v-if="image" class="program_image" :src="image" />
    <div class="program_content">
      <h1 class="program_title">
        {{ currentProgram?.title }}
      </h1>
      <p class="program_subtitle">{{ currentProgram?.human_schedule_copy }}</p>
    </div>
  </section>
</template>

<script>
import { mapState } from 'pinia'
import { useNowPlayingStore } from '../stores/nowPlaying'
import { cdnImageUrl } from '@dpgradio/creative'

export default {
  name: 'CurrentProgram',
  computed: {
    ...mapState(useNowPlayingStore, ['currentProgram']),
    image() {
      return cdnImageUrl(
        this.currentProgram?.header_images?.filter((image) => 'program_square' in image)?.[0]?.['program_square']
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../style/mixins.scss';
.program_container {
  height: 100%;
  display: flex;
  align-items: center;
  color: rgb(var(--color-text-primary));

  .program_image {
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 9999px;
    margin-right: 10px;
    height: 70%;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .program_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }

  .program_title {
    @extend .title-text;
    color: rgb(var(--color-text-primary));
    margin: 0;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1;
  }

  .program_subtitle {
    font-size: 2rem;
    margin: 0;
    font-family: var(--font-body);
    color: #374151;
  }
}
</style>
