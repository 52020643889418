<template>
  <div class="widgets">
    <component :is="widget.widget" v-bind="widget.props" v-for="widget in widgets" :key="widget.id" :widget="widget" />
  </div>
</template>

<script>
import { useWidgets } from '../utils/useWidgets'

export default {
  name: 'Widgets',
  setup() {
    const widgets = useWidgets()
    return { widgets }
  },
}
</script>

<style lang="scss" scoped>
.widgets {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
</style>
