<template>
  <img :src="imageSrc" class="cover_image" />
</template>

<script>
import { cdnImageUrl, config } from '@dpgradio/creative'
import WillyCoverPlaceholder from '../assets/images/willy-cover-placeholder.jpg'
import JoeCoverPlaceholder from '../assets/images/joe-cover-placeholder.jpg'
import QMusicCoverPlaceholder from '../assets/images/qmusic-cover-placeholder.jpg'

export default {
  name: 'CoverImage',
  props: {
    track: {
      type: Object,
      default: null,
    },
  },
  computed: {
    imageSrc() {
      return cdnImageUrl(this.track?.thumbnail, 'w480') || this.defaultImageSrc
    },
    defaultImageSrc() {
      switch (config('app.theme')) {
        case 'willy-theme':
          return WillyCoverPlaceholder
        case 'joe-theme':
          return JoeCoverPlaceholder
        default:
          return QMusicCoverPlaceholder
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cover_image {
  aspect-ratio: 1 / 1;
  display: inline-block;
  border-radius: 0.5rem;
  height: 100%;
  margin-right: 1rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
</style>
