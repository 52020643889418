import { defineStore } from 'pinia'
import { socket } from '@dpgradio/creative'

const ITEM_TYPES = ['Song', 'Commercial', 'Trigger']

export const useNowPlayingStore = defineStore('nowPlaying', {
  state: () => ({
    plays: [],
    currentProgram: null,
  }),
  getters: {
    currentItem() {
      return this.plays.filter((play) => ITEM_TYPES.includes(play.type)).slice(-1)[0] ?? null
    },
    nextTrack() {
      const currentItem = this.currentItem
      if (currentItem && Object.keys(currentItem).includes('next')) {
        return currentItem.next
      }
      return null
    },
  },
  actions: {
    initialize(stationId) {
      this.plays = []
      socket
        .connect(stationId)
        .subscribe('actions')
        .listen(
          (play) => {
            this.plays.push(play)
          },
          { backlog: 15 }
        )

      socket.join({ station: stationId, entity: 'program', action: 'change', options: { backlog: 1 } }, (program) => {
        this.currentProgram = program
      })
    },
  },
})
