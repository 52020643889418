import { defineStore } from 'pinia'
import { api, config } from '@dpgradio/creative'

const urlParams = new URLSearchParams(window.location.search)
const API_KEY = import.meta.env.VITE_API_KEY || urlParams.get('api_key')

export const useChannelsStore = defineStore('channels', {
  state: () => ({
    channels: [],
    listenCount: [],
  }),
  getters: {
    main() {
      return this.channels[0]
    },
    listenersCountChannel(state) {
      return (stationId) => state.listenCount?.find((station) => station.station_id === stationId)?.total || 0
    },
    filteredChannels() {
      return this.channels.filter((station) => config('app.channels').split(',').includes(station.station.station_id))
    },
  },
  actions: {
    async initialize() {
      const channels = await api.channels.all()

      this.channels = channels.map((channel) => ({ ...channel, station: channel.data }))
      api.setApiKey(API_KEY)

      this.fetchListenCount()
      setInterval(() => this.fetchListenCount(), 60000)
    },
    async fetchListenCount() {
      this.listenCount = (await api.global().request().get('/metrics/listeners')).metrics?.stations.map((metric) => ({
        station_id: metric.station_id,
        total: metric.total,
      }))
    },
  },
})
