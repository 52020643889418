import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { configuration } from '@dpgradio/creative'
import './style/style.scss'
import App from './App.vue'
import { useNowPlayingStore } from './stores/nowPlaying'
import { useChannelsStore } from './stores/channels'

const initialize = async () => {
  await configuration.retrieveConfigForDetectedStation('live-view-dashboard')
  const pinia = createPinia()
  const app = createApp(App)

  app.use(pinia)
  app.mount('#app')

  useNowPlayingStore().initialize(configuration.stationId)
  useChannelsStore().initialize()
}

initialize()
