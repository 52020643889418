<template>
  <div v-if="currentItem" :key="item_key" class="item_container">
    <div class="item_percentage" />
    <div class="item">
      <component :is="currentItem.type" :item="currentItem" />
      <div class="item_timer">
        <h1 class="item_duration">{{ item_duration }}</h1>
      </div>
      <div class="item_border" />
    </div>
    <div v-if="nextTrack" class="next-item">
      <span>Next Song:</span>
      <Song :small="true" :item="nextTrack" />
    </div>
  </div>
</template>

<script>
import { intervalToDuration, addSeconds } from 'date-fns'
import { mapState } from 'pinia'
import { useNowPlayingStore } from '../stores/nowPlaying'
import { useNow } from '../utils/useNow'
import Song from './Items/Track.vue'
import Commercial from './Items/Commercial.vue'
import Trigger from './Items/Trigger.vue'

export default {
  name: 'CurrentItem',
  components: {
    Song,
    Commercial,
    Trigger,
  },
  setup() {
    const now = useNow()
    return { now }
  },
  computed: {
    ...mapState(useNowPlayingStore, ['currentItem', 'nextTrack']),
    duration() {
      return this.currentItem?.duration
    },
    item_start() {
      return new Date(this.currentItem?.played_at)
    },
    item_end() {
      return addSeconds(this.item_start, this.currentItem?.duration)
    },
    item_key() {
      return this.currentItem?.id ?? this.currentItem?.track_id
    },
    item_duration() {
      if (this.item_end < this.now) {
        return '00:00'
      }
      const duration = intervalToDuration({ start: this.now, end: this.item_end })
      return `${this.zeroPad(duration.minutes)}:${this.zeroPad(duration.seconds)}`
    },
    item_percentage() {
      const percentage = Math.min(
        ((this.now.getTime() - this.item_start.getTime()) / (this.item_end.getTime() - this.item_start.getTime())) *
          100,
        100
      )

      return `${percentage}%`
    },
  },
  methods: {
    zeroPad(number) {
      return `0${number}`.slice(-2)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../style/mixins.scss';

.item_container {
  display: flex;
  position: relative;
  padding: 1rem;
  min-width: 50%;
  height: 80%;
  padding-right: 20px;
  align-items: center;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  background-color: rgba(var(--color-primary-transparent));
  box-sizing: border-box;

  .item_percentage {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: v-bind(item_percentage);
    height: 100%;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    background-color: rgba(var(--color-primary-transparent));
    transition: all 1s linear;
  }

  .item_border {
    position: absolute;
    right: 0;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    width: 0.75rem;
    background-color: rgb(var(--color-primary));
    height: 100%;
  }

  .item {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 2rem);
    z-index: 2;
  }

  .item_duration {
    @extend .title-text;
    font-family: var(--font-mono);
    margin: 0;
    color: rgb(var(--color-text-primary));
    font-size: 3rem;
    font-weight: 600;
    line-height: 1;
    text-overflow: ellipsis;
    z-index: 2;
  }

  .next-item {
    @extend .title-text;
    display: flex;
    z-index: 2;
    gap: 5px;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 5px;
    color: rgb(var(--color-text-primary));
  }
}
</style>
